import { LoaderFunctionArgs, type MetaFunction } from "@remix-run/node";
import { Link, useLoaderData, useNavigation } from "@remix-run/react";
import { authkitLoader } from "@workos-inc/authkit-remix";
import { User } from "@workos-inc/node";
import { Camera, ChartBar, LoaderIcon, QrCode, Upload } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Button } from "~/components/ui/button";

export const meta: MetaFunction = () => {
  return [
    { title: "Assessain.com" },
    { name: "description", content: "Revolutionize Your Assessments with AI" },
  ];
};

export const loader = async (args: LoaderFunctionArgs) => {
  return authkitLoader(args);
};

interface HeroSectionProps {
  user: User | null;
}

const HeroSection = ({ user }: HeroSectionProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  return (
    <section
      id="hero"
      className="flex justify-center items-center min-h-screen bg-gray-50 text-gray-900"
    >
      <div className="text-center max-w-3xl p-8">
        <h1 className="text-5xl font-bold leading-tight mb-4">
          Revolutionize Your Assessments with AI
        </h1>
        <p className="text-lg text-gray-600 mb-6">
          Say goodbye to manual grading and hello to seamless, AI-powered
          evaluations. Assess<i>ai</i>n automates the process so you can focus
          on what really matters—helping your students succeed.
        </p>
        <Button asChild variant="default" size="lg" className="mb-8">
          {navigation.state === "loading" ? (
            <LoaderIcon className="animate-spin text-white" />
          ) : user ? (
            <Link to="/app">{t("nav.goToApp")}</Link>
          ) : (
            <Link to="/auth/signup">Get Started for Free</Link>
          )}
        </Button>
        <p className="text-gray-500">
          No credit card required. Get started in less than 2 minutes!
        </p>
      </div>
    </section>
  );
};

const FeaturesSection = () => (
  <section id="features" className="py-16 bg-gray-50">
    <div className="container mx-auto text-center">
      <h2 className="text-3xl font-bold mb-8 text-gray-900">
        Key Features to Simplify Your Workflow
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {[
          {
            title: "Upload Worksheets",
            icon: Upload,
            description: "Easily upload paper quizzes and exams for analysis.",
          },
          {
            title: "Generate Printables",
            icon: QrCode,
            description:
              "Create QR-coded worksheets for students to scan and complete.",
          },
          {
            title: "Scan and Evaluate",
            icon: Camera,
            description: "Automatically evaluate answers submitted via scan.",
          },
          {
            title: "View Results",
            icon: ChartBar,
            description: "Receive instant feedback and performance reports.",
          },
        ].map((feature, index) => (
          <div
            key={index}
            className="bg-white p-6 shadow-md rounded-lg hover:shadow-lg transition-shadow duration-300"
          >
            <feature.icon className="w-12 h-12 mx-auto text-teal-600 mb-4" />
            <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
            <p className="text-gray-600">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

const TestimonialsSection = () => (
  <section id="testimonials" className="py-16 bg-white">
    <div className="container mx-auto text-center">
      <h2 className="text-3xl font-bold mb-12 text-gray-900">
        What Our Users Are Saying
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {[
          {
            name: "John Doe",
            role: "Math Teacher",
            picture: "/john.webp",
            quote:
              "This tool has transformed the way I grade papers. Super efficient!",
          },
          {
            name: "Jane Smith",
            role: "Science Teacher",
            picture: "/jane.webp",
            quote:
              "I love how easy it is to generate printables with QR codes!",
          },
          {
            name: "Mark Johnson",
            role: "Administrator",
            picture: "/mark.webp",
            quote: "Our school’s grading process has never been this smooth!",
          },
        ].map((testimonial, index) => (
          <div
            key={index}
            className="bg-gray-100 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
          >
            <img
              src={testimonial.picture}
              alt={`${testimonial.name}`}
              className="h-16 w-16 mx-auto rounded-full bg-gray-300 mb-4"
            />
            <p className="italic text-gray-700 mb-4">“{testimonial.quote}”</p>
            <h3 className="font-semibold text-gray-900">{testimonial.name}</h3>
            <p className="text-teal-500">{testimonial.role}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

const HowItWorksSection = () => (
  <section id="howitworks" className="py-16 bg-gray-50">
    <div className="container mx-auto text-center">
      <h2 className="text-3xl font-bold mb-12 text-gray-900">How It Works</h2>
      <div className="flex flex-col space-y-8">
        {[
          {
            step: "1",
            title: "Upload Worksheet",
            description:
              "Upload PDFs or Word documents of your worksheets for easy digital conversion.",
          },
          {
            step: "2",
            title: "Generate Printables",
            description: "Generate unique QR-coded printables for students.",
          },
          {
            step: "3",
            title: "Distribute to Students",
            description: "Hand out or assign worksheets digitally with ease.",
          },
          {
            step: "4",
            title: "Students Scan and Submit",
            description:
              "Students scan their answers using smartphones, uploading answers instantly.",
          },
          {
            step: "5",
            title: "Instant AI Evaluation",
            description:
              "Get real-time feedback and results from the AI-driven assessment system.",
          },
        ].map((item, index) => (
          <div key={index} className="flex flex-col items-center">
            <div className="bg-teal-500 text-white w-12 h-12 flex items-center justify-center rounded-full text-lg mb-4">
              {item.step}
            </div>
            <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
            <p className="text-gray-600 text-center max-w-xs">
              {item.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

const CallToAction = () => (
  <section
    id="calltoaction"
    className="py-16 bg-teal-600 text-white text-center"
  >
    <div className="container mx-auto">
      <h2 className="text-4xl font-bold mb-4">
        Ready to Transform Your Grading Process?
      </h2>
      <p className="text-lg mb-8">
        Join educators who are simplifying their assessment workflow with Assess
        <i>ai</i>n.
      </p>
      {/* <SignInButton
        text="Upload Your First Worksheet Now"
        variant="secondary"
        size="lg"
        className="bg-white text-teal-600 hover:bg-gray-100 transition-colors"
      /> */}
    </div>
  </section>
);

export default function Index() {
  const { user } = useLoaderData<typeof loader>();

  return (
    <>
      <HeroSection user={user} />
      <FeaturesSection />
      <TestimonialsSection />
      <HowItWorksSection />
      <CallToAction />
    </>
  );
}
